import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import instaIcon from "./img/instagram.png"
import "./styles.scss"

const Footer = ({ langSliced, handleTransition, navItem }) => {
  return (
    <motion.div variants={navItem} custom={5} className="header-nav-footer">
      <a
        href="https://www.instagram.com/dashad.art"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instaIcon} width="30" height="30" alt="instagram icon" />
        <p>@DashaD.Art</p>
      </a>
      <ul className="header-nav-footer-lang">
        <li
          className={
            langSliced === "en"
              ? "header-nav-footer-lang-item-active"
              : "header-nav-footer-lang-item"
          }
        >
          <Link
            to={"/en"}
            onClick={e => {
              e.preventDefault()
              handleTransition("/en")
            }}
          >
            EN
          </Link>
        </li>
        <li
          className={
            langSliced === "fr"
              ? "header-nav-footer-lang-item-active"
              : "header-nav-footer-lang-item"
          }
        >
          <Link
            to={"/fr"}
            onClick={e => {
              e.preventDefault()
              handleTransition("/fr")
            }}
          >
            FR
          </Link>
        </li>
        <li
          className={
            langSliced === "ru"
              ? "header-nav-footer-lang-item-active"
              : "header-nav-footer-lang-item"
          }
        >
          <Link
            to={"/ru"}
            onClick={e => {
              e.preventDefault()
              handleTransition("/ru")
            }}
          >
            РУ
          </Link>
        </li>
      </ul>
    </motion.div>
  )
}

export default Footer
