const menu_EN = [
  {
    slug: "paintings",
    h3: "artworks",
    h2: "Paintings",
  },
  {
    slug: "paper",
    h3: "artworks",
    h2: "Paper",
  },
  {
    slug: "galleries",
    h3: "galleries",
    h2: "Where to find?",
  },
  {
    slug: "news",
    h3: "news",
    h2: "What’s new?",
  },
  {
    slug: "contact",
    h3: "contact",
    h2: "Get in touch",
  },
]

export default menu_EN
