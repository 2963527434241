import React, { useContext } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { isMobile } from "react-device-detect"

import { GlobalStateContext } from "../../../context/GlobalContextProvider"

import "./styles.scss"

const HeaderBar = ({ langSliced, handleTransition, location }) => {
  const state = useContext(GlobalStateContext)
  const toggleLightBox = state.toggleLightBox
  const slug = `/${langSliced}`
  const path = location.pathname

  return (
    <motion.div className="header-bar">
      <Link
        to={slug}
        className={
          toggleLightBox ? "header-bar-link hidden" : "header-bar-link"
        }
        onClick={e => {
          e.preventDefault()
          handleTransition(slug)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-labelledby="DLogo"
          className={
            toggleLightBox ? "header-bar-logo hidden" : "header-bar-logo"
          }
          viewBox="0 0 64.5 86.1"
        >
          <title id="DLogo">Logo Dmitrieva</title>
          <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
              <path d="M57.9,38.3c-1,1.2-1.8,2.6-2.8,3.9a44.2,44.2,0,0,1-4,4.8c-2.5,2.5-5.1,5.1-7.9,7.4a80.3,80.3,0,0,1-6.9,5c-2.9,1.9-5.8,3.8-8.9,5.5a34.3,34.3,0,0,1-5.5,2.5,2.1,2.1,0,0,0-1.6,1.8c-.9,4-6.3,14.7-6.7,15.7a2.4,2.4,0,0,1-.9,1.1c-.7.3-1.2-.2-1.1-.9s.4-1.9.5-2.8a7.1,7.1,0,0,0,.1-2.5c-.2-1,.3-2,.5-3.1A48.1,48.1,0,0,1,14.2,70H11.1a20.9,20.9,0,0,1-3.6-.8C4.8,68.1,4.2,66,4.3,63A9.8,9.8,0,0,1,7,56.9a37.7,37.7,0,0,1,12.1-10,3.7,3.7,0,0,0,2.3-2.8q1.2-5.9,2.7-11.7c.8-3.1,1.7-6,2.6-9.1a2.4,2.4,0,0,0,.2-.9l-.2-.2c-1.3.6-2.7,1.1-4,1.8a105.1,105.1,0,0,0-11.4,6.2,42.9,42.9,0,0,0-5.5,4,23.5,23.5,0,0,0-1.9,2.5,2.2,2.2,0,0,1-2,1.1A1.7,1.7,0,0,1,.3,35.1a23.2,23.2,0,0,1,5.8-5.9l9.5-6.5a52.1,52.1,0,0,1,12.5-6.2,1.2,1.2,0,0,0,.9-1.1c.7-3.3,1.4-6.6,2.2-9.8.3-1.4.7-2.8,1.1-4.2A2,2,0,0,1,34,0a2.5,2.5,0,0,1,2.9,2.6c.1,1.5-.7,2.6-1,3.9s-1.3,5.1-2,7.9l1.6-.5c2.1-.6,4.2-1.4,6.4-1.8a52.6,52.6,0,0,1,7.8-1.2,24.3,24.3,0,0,1,7.6.4,9.3,9.3,0,0,1,5.7,4c1.7,2.6,1.7,5.4,1.2,8.2a41.2,41.2,0,0,1-3.3,10.1c-.1.1-.1.2-.1.4M26.7,43.1l2.5-1.2,14.9-6.1a32.7,32.7,0,0,1,9.8-2.4c.3,0,.6-.4.8-.7,1.6-2.6,2.1-5.6,2.7-8.5.9-4.5-3.6-7.9-6.7-8.1a21.6,21.6,0,0,0-6.2.9c-3.7.9-7.4,2-11,3a1.5,1.5,0,0,0-1.4,1.6c-1,4.5-2.1,8.9-3.2,13.4C28.3,37.6,27.5,40.2,26.7,43.1ZM21.8,63.4c11.3-5,19.7-13,27.1-22.2a1.8,1.8,0,0,0-1,.2l-7.3,2.7a144.6,144.6,0,0,0-15.2,6.5,2.1,2.1,0,0,0-1.2,1.7c-.6,2.4-1.1,4.8-1.7,7.2S22,62,21.8,63.4Zm-3.3-8.6h-.2c-.7.6-1.5,1-2.1,1.6a28.9,28.9,0,0,0-3.5,3.5c-1,1.2-1.9,2.5-1.5,4.3s.9,2,2.4,1.8l1.1-.2a1.3,1.3,0,0,0,1.4-1.3c.5-2.1.9-4.1,1.4-6.1S18.2,56,18.5,54.8Z" />
            </g>
          </g>
        </svg>
      </Link>

      <p
        className={
          toggleLightBox ? "header-bar-copyright white" : "header-bar-copyright"
        }
      >
        © Dmitrieva Art 2020
      </p>
    </motion.div>
  )
}

export default HeaderBar
