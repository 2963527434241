const menu_FR = [
  {
    slug: "paintings",
    h3: "œuvres",
    h2: "Peintures",
  },
  {
    slug: "paper",
    h3: "œuvres",
    h2: "Papier",
  },
  {
    slug: "galleries",
    h3: "galeries",
    h2: "Où trouver ?",
  },
  {
    slug: "news",
    h3: "actualités",
    h2: "Quoi de neuf ?",
  },
  {
    slug: "contact",
    h3: "contact",
    h2: "Prendre contact",
  },
]

export default menu_FR
