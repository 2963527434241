const menu_RU = [
  {
    slug: "paintings",
    h3: "РАБОТЫ",
    h2: "Живопись",
  },
  {
    slug: "paper",
    h3: "РАБОТЫ",
    h2: "Графика",
  },
  {
    slug: "galleries",
    h3: "Галереи",
    h2: "Где можно найти ?",
  },
  {
    slug: "news",
    h3: "Новости",
    h2: "Что нового ?",
  },
  {
    slug: "contact",
    h3: "Контакт",
    h2: "Связаться",
  },
]

export default menu_RU
