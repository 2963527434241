export const navUnderlay = {
  visible: {
    opacity: 0.8,
    transition: {
      type: "spring",
      bounce: 0.05,
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      type: "spring",
      bounce: 0.05,
      duration: 0.8,
      delay: 0.3,
    },
  },
}

export const navBg = {
  visible: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.05,
      duration: 0.8,
      delay: 0.1,
    },
  },
  hidden: {
    y: "100vh",
    transition: {
      type: "spring",
      bounce: 0.05,
      duration: 0.6,
      delay: 0.2,
    },
  },
}

export const navItem = {
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.05 + 0.5,
      type: "spring",
      bounce: 0.1,
      duration: 0.7,
    },
  }),
  hidden: {
    opacity: 0,
    y: "70%",
    transition: {
      type: "spring",
      bounce: 0.05,
      duration: 0.4,
    },
  },
}
