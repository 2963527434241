import React, { useState, useContext } from "react"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"
import "./styles.scss"

const BurgerMenu = ({ langSliced }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleBurger = state.toggleBurger
  const toggleLightBox = state.toggleLightBox
  const handleToggleNav = () => {
    dispatch({ type: "TOGGLE_NAV" })
    dispatch({ type: "TOGGLE_BURGER" })
  }

  return (
    <div
      onClick={handleToggleNav}
      className={toggleLightBox ? "menu hidden" : "menu"}
    >
      <p data-ison={toggleBurger}>
        {langSliced === "en" || langSliced === "fr" ? "Menu" : "Меню"}
      </p>
      <div>
        <div
          className={toggleLightBox ? "burger hidden" : "burger"}
          data-ison={toggleBurger}
        >
          <div>
            <div>
              <span />
              <span />
            </div>
            <svg>
              <use xlinkHref="#path" />
            </svg>
            <svg>
              <use xlinkHref="#path" />
            </svg>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            id="path"
          >
            <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
          </symbol>
        </svg>
      </div>
    </div>
  )
}

export default BurgerMenu
