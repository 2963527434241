// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/contactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-galleries-page-jsx": () => import("./../../../src/templates/galleriesPage.jsx" /* webpackChunkName: "component---src-templates-galleries-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/homePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-news-page-jsx": () => import("./../../../src/templates/newsPage.jsx" /* webpackChunkName: "component---src-templates-news-page-jsx" */),
  "component---src-templates-painting-gallery-jsx": () => import("./../../../src/templates/paintingGallery.jsx" /* webpackChunkName: "component---src-templates-painting-gallery-jsx" */),
  "component---src-templates-paintings-page-jsx": () => import("./../../../src/templates/paintingsPage.jsx" /* webpackChunkName: "component---src-templates-paintings-page-jsx" */),
  "component---src-templates-paper-gallery-jsx": () => import("./../../../src/templates/paperGallery.jsx" /* webpackChunkName: "component---src-templates-paper-gallery-jsx" */),
  "component---src-templates-paper-page-jsx": () => import("./../../../src/templates/paperPage.jsx" /* webpackChunkName: "component---src-templates-paper-page-jsx" */),
  "component---src-templates-success-page-jsx": () => import("./../../../src/templates/successPage.jsx" /* webpackChunkName: "component---src-templates-success-page-jsx" */)
}

