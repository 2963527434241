import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  togglePreloader: true,
  toggleTransition: true,
  toggleNav: false,
  toggleBurger: false,
  toggleLightBox: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_PRELOADER": {
      return {
        ...state,
        togglePreloader: !state.togglePreloader,
      }
    }
    case "TOGGLE_TRANSITION": {
      return {
        ...state,
        toggleTransition: !state.toggleTransition,
      }
    }
    case "TOGGLE_NAV": {
      return {
        ...state,
        toggleNav: !state.toggleNav,
      }
    }
    case "TOGGLE_BURGER": {
      return {
        ...state,
        toggleBurger: !state.toggleBurger,
      }
    }
    case "TOGGLE_LIGHTBOX": {
      return {
        ...state,
        toggleLightBox: !state.toggleLightBox,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
