import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import "./styles.scss"

const NavItem = ({
  item,
  index,
  langSliced,
  handleTransition,
  handleNavOver,
  handleNavOut,
  navItem,
}) => {
  const slug = `/${langSliced}/${item.slug}`
  const data = useStaticQuery(graphql`
    query Paintings {
      paintings: file(relativePath: { eq: "paintings.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper: file(relativePath: { eq: "paper.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      galleries: file(relativePath: { eq: "galleries.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      news: file(relativePath: { eq: "news.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const paintings = data.paintings.childImageSharp.fluid
  const paper = data.paper.childImageSharp.fluid
  const galleries = data.galleries.childImageSharp.fluid
  const news = data.news.childImageSharp.fluid
  const contact = data.contact.childImageSharp.fluid

  return (
    <motion.li
      key={item.slug}
      variants={navItem}
      custom={index}
      className="header-nav-list-item"
      whileHover={{
        x: 10,
        transition: { duration: 0.2, ease: "easeInOut" },
      }}
      onHoverStart={() => {
        handleNavOver(
          item.slug === "paintigs"
            ? paintings
            : item.slug === "paper"
            ? paper
            : item.slug === "galleries"
            ? galleries
            : item.slug === "news"
            ? news
            : item.slug === "contact"
            ? contact
            : paintings
        )
      }}
      onHoverEnd={() => {
        handleNavOut()
      }}
    >
      <Link
        to={slug}
        onClick={e => {
          e.preventDefault()
          handleTransition(slug)
        }}
      >
        <h3>{item.h3}</h3>
        <h2>{item.h2}</h2>
      </Link>
    </motion.li>
  )
}

export default NavItem
