import React, { useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import { GlobalStateContext } from "../../../context/GlobalContextProvider"
import List from "./List"
import Footer from "./Footer"
import { navUnderlay, navBg, navItem } from "./framer-anim"
import "./styles.scss"

const Nav = ({ langSliced, handleTransition }) => {
  const data = useStaticQuery(graphql`
    query PaintingsImage {
      paintings: file(relativePath: { eq: "paintings.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const paintings = data.paintings.childImageSharp.fluid

  const [imageClassName, setImageClassName] = useState("header-nav-image")
  const [imageUrl, setImageUrl] = useState(paintings)

  const state = useContext(GlobalStateContext)
  const toggleNav = state.toggleNav

  const handleNavOver = url => {
    setTimeout(() => {
      setImageClassName("header-nav-image hover")
    }, 50)
    setImageUrl(url)
  }

  const handleNavOut = () => {
    setImageClassName("header-nav-image")
  }
  return (
    <>
      <motion.div
        initial="hidden"
        animate={toggleNav ? "visible" : "hidden"}
        variants={navUnderlay}
        className="header-underlay"
      />

      <motion.div
        className="header-mask"
        initial="hidden"
        animate={toggleNav ? "visible" : "hidden"}
        variants={navBg}
      >
        <nav className="header-nav">
          <Img className={imageClassName} fluid={imageUrl} />
          <List
            langSliced={langSliced}
            handleNavOut={handleNavOut}
            handleNavOver={handleNavOver}
            handleTransition={handleTransition}
            navItem={navItem}
          />
          <Footer
            langSliced={langSliced}
            handleTransition={handleTransition}
            navItem={navItem}
          />
        </nav>
      </motion.div>
    </>
  )
}

export default Nav
