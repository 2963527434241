import React from "react"

import menu_EN from "./menu_EN"
import menu_FR from "./menu_FR"
import menu_RU from "./menu_RU"
import NavItem from "../NavItem"
import "./styles.scss"

const List = ({
  langSliced,
  handleNavOut,
  handleNavOver,
  handleTransition,
  navItem,
}) => {
  return (
    <ul className="header-nav-list">
      {langSliced === "en" &&
        menu_EN.map((item, index) => (
          <NavItem
            key={item.h2}
            item={item}
            index={menu_EN.length - index + 1}
            langSliced={langSliced}
            handleTransition={handleTransition}
            handleNavOver={handleNavOver}
            handleNavOut={handleNavOut}
            navItem={navItem}
          />
        ))}
      {langSliced === "fr" &&
        menu_FR.map((item, index) => (
          <NavItem
            key={item.h2}
            item={item}
            index={menu_FR.length - index + 1}
            langSliced={langSliced}
            handleTransition={handleTransition}
            handleNavOver={handleNavOver}
            handleNavOut={handleNavOut}
            navItem={navItem}
          />
        ))}
      {langSliced === "ru" &&
        menu_RU.map((item, index) => (
          <NavItem
            key={item.h2}
            item={item}
            index={menu_RU.length - index + 1}
            langSliced={langSliced}
            handleTransition={handleTransition}
            handleNavOver={handleNavOver}
            handleNavOut={handleNavOut}
            navItem={navItem}
          />
        ))}
    </ul>
  )
}

export default List
